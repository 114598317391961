$primary-green: #005bd3;
$primary-orange: #ffc439;
$white: #fff;
$gray-eighty: #ccc;
$gray-light: #999;
$gray-medium: #666;
$gray-dark: #333;
$black: #000;
$red: #e23d3d;
$gray-light-bg: #f5f5f5;
$gray-medium-bg: #f1f1f1;
$gray-dark-bg: #dddddd;
$green-light-bg: rgba(147, 220, 156, 0.15);
