// Theme White

// Variables

$app-container-bg: #808080;
$app-sidebar-bg: rgb(255, 255, 255);
$app-header-bg:  rgba(246, 249, 251, 0.931);
$app-header-logo-bg: rgba(251, 251, 251, 0.931);

// Content

.app-theme-white {

  &.app-container {
    background: $app-container-bg;
    background-color: #80808000;
 
  }

  .app-sidebar-bg {
    
    height: 100%; 
    padding: 0;
  }
  .app-sidebar {
    background: $app-sidebar-bg;
    opacity: 0.9;
  }
  .app-sidebar-bg {
    
    height: 100%;
    padding: 0;
  }
  .app-page-title {
    background: rgba(255, 255, 255, .55);
    height: 100px;
  }

  .app-footer .app-footer__inner,
  .app-header {
    background: $app-header-bg;opacity: 0.95;
  }

  &.fixed-header {
    .app-header__logo {
      background: rgba($app-header-bg, .1);opacity: 0.95;
    }
  }

}