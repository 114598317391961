.modal-wrapper {
  display: none;
  background: rgba(0, 0, 0, 0.596);
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  z-index: 999;
  width: 100%;
  overflow-y: hidden;
  &.active {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    -webkit-align-items: center;
    -webkit-justify-content: center;
  }
  .modal {
    width: 100%;
    max-width: 532px;
    margin: 16px;
    background: #fff;
    position: relative;
    border-radius: 4px;
    z-index: 999;
  }
  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 24px;
    line-height: 36px;
    border-radius: 0 4px 0 0;
    background: transparent;
    color: $gray-medium;
    &:hover {
      background: $gray-dark-bg;
    }
  }
}
.quick-view-details {
  padding: 24px;
  background: $gray-medium-bg;
  z-index: 999;
  border-radius: 0 0 4px 4px;
  .product-price {
    font-size: 18px;
  }
  .product-price {
    color: $primary-green;
    font-weight: bold;
    font-size: 24px;
    float: center;
    &:before {
      content: " $";
    }
  }
}
.quick-view-image {
  img {
    max-width: 350px;
    max-height: 525px;
    margin: 0 auto;
    display: block;
    border-radius: 4px 4px 0 0;
  }
}
